<template>
  <div class="progress-container">
    <div
      v-for="item in items"
      :key="item.id"
      class="mt-4 pa-3 white rounded elevation-2"
    >
      <div>
        <div class="text-subtitle-2 mb-2 d-flex justify-space-between">
          <span>
            {{ item.text }}
          </span>

          <span
            v-if="item.subtext"
            class="font-weight-bold"
          >
            {{ item.subtext }}
          </span>
        </div>

        <v-progress-linear
          :value="item.value"
          :indeterminate="item.isIndeterminate"
        ></v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBars",

  computed: {
    items() {
      return this.$store.getters["progress/get"].map((item) => ({
        ...item,
        value: Math.floor((item.progress * 100) / item.total)
      }))
    }
  }
}
</script>

<style lang="stylus" scoped>
.progress-container
  position fixed
  bottom 1em
  right 1em
  z-index 4000
  max-width 300px
  width 100%
</style>
